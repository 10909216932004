import Vue from 'vue';
import App from './App.vue';
import dayjs from 'dayjs';
import QuestionHub from './messages-hub';
Vue.config.productionTip = false;

import './scss/main.scss';

Vue.use(QuestionHub);
Vue.prototype.$dayjs = dayjs;

new Vue({
    render: (h) => h(App),
}).$mount('#app');
