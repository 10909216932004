<template>
    <div id="app">
        <MainInput />
        <StatusBar />
    </div>
</template>

<script>
import MainInput from './components/MainInput.vue';
import StatusBar from './components/StatusBar.vue';
export default {
    name: 'App',
    components: {
        MainInput,
        StatusBar,
    },
};
</script>
<style scoped scss>
#app {
    margin: 0;
    padding: 0;
    width: 100%;
    border: 2px solid green;

    height: 100vh;
    display: flex;
    flex-direction: column;
}
</style>
