<template>
    <div>
        <span class="time"> {{ getTime() }} -!- {{ message }} </span>
    </div>
</template>

<script>
export default {
    name: 'ServerMessage',
    props: {
        message: {
            type: String,
            required: true,
        },
    },
    methods: {
        getTime() {
            const time = new Date();
            return this.$dayjs(time).format('HH:mm:ss');
        },
    },
};
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
