<template>
    <div>
        <span class="time"
            >{{ date
            }}<template v-if="author">&lt;{{ author }}&gt;</template></span
        >
        {{ message }}
    </div>
</template>

<script>
export default {
    name: 'InfoMessage',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            message: '',
            date: '',
            author: '',
            letterTimeOut: 100,
        };
    },
    created() {
        this.typewriter(this.data.message);
        this.author = this.data.author;
        this.date = this.createTime(this.data.dateTime);
    },
    mounted() {},
    methods: {
        createTime(time) {
            if (time === '') {
                return 'geen tijd';
            }
            return this.$dayjs(time).format('HH:mm:ss');
        },
        typewriter(str) {
            let n = 0;
            const typeTimer = setInterval(() => {
                n++;
                this.message = '> ' + str.slice(0, n);
                if (n === str.length) {
                    clearInterval(typeTimer);
                }
            }, 100);
        },
    },
};
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
