<template>
    <div class="status-bar">
        <div class="log">
            <span>Status: {{ status }}</span>
        </div>
        <MorseCode />
    </div>
</template>

<script>
import MorseCode from '@/components/MorseCode.vue';
export default {
    name: 'StatusBar',
    components: {
        MorseCode,
    },
    data() {
        return {
            status: '',
        };
    },
    created() {},
    mounted() {
        this.$questionHub.$on('connected', this.isConnected);
        this.$questionHub.$on('connectionfailed', this.isDisconnected);
    },
    methods: {
        isConnected() {
            this.status = 'connected';
        },
        isDisconnected() {
            this.status =
                "there's no connection to the backend. We will not be able to give you hints.";
        },
    },
};
</script>
