import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import Vue from 'vue';
export default {
    install() {
        const connection = new HubConnectionBuilder()
            .withUrl('https://birds.timeforgames.nl/messages-hub')
            .configureLogging(LogLevel.Information)
            .build();
        // use new Vue instance as an event bus
        const questionHub = new Vue();
        // every component will use this.$questionHub to access the event bus
        Vue.prototype.$questionHub = questionHub;

        let startedPromise = null;
        function start() {
            startedPromise = connection
                .start()
                .then(() => {
                    console.log('connected');
                    questionHub.$emit('connected');
                })
                .catch((err) => {
                    console.error('Failed to connect with hub', err);
                    questionHub.$emit('connectionfailed');
                    return new Promise((resolve, reject) =>
                        setTimeout(
                            () => start().then(resolve).catch(reject),
                            5000
                        )
                    );
                });
            return startedPromise;
        }
        connection.onclose(() => start());

        connection.on('sentMessage', (message) => {
            questionHub.$emit('sentMessage', { message });
        });
        connection.on('incorrectAnswer', (message) => {
            questionHub.$emit('incorrectAnswer', { message });
        });
        start();
    },
};
