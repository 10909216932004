<template>
    <div class="main-input">
        <div v-if="isAdmin">
            <input type="text" v-model="hintText" v-on:keyup.enter="sendHint" />
            <button @click.prevent="sendHint">Send Hint</button>
        </div>
        <ul class="messages">
            <li v-for="(message, index) in messages" :key="index">
                <server-message
                    :message="message.message"
                    v-if="message.messageType == 'server'"
                />
                <info-message
                    v-else-if="message.messageType == 'info'"
                    :data="message"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import ServerMessage from '@/components/messages/ServerMessage.vue';
import InfoMessage from '@/components/messages/InfoMessage.vue';
export default {
    name: 'MainInput',
    components: {
        ServerMessage,
        InfoMessage,
    },
    data() {
        return {
            encryptionMessages: [
                'Shutting down Microsoft Teams',
                'Disabling RDP-access on all servers',
                'ACCESSING s-w3s-www01',
                'Replacing all websites on s-w3s-www01 with HACKED MESSAGES',
                'Encrypting s-w3s-www01 C:-drive',
                'Encrypting s-w3s-www01 D:-drive',
                'Encrypting s-w3s-www01 E:-drive',
                'ACCESSING s-w3s-www02',
                'Replacing all websites on s-w3s-www02 with HACKED MESSAGES',
                'Encrypting s-w3s-www02 C:-drive',
                'Encrypting s-w3s-www02 D:-drive',
                'Encrypting s-w3s-www02 E:-drive',
                'ACCESSING s-w3s-www03',
                'Replacing all websites on s-w3s-www03 with HACKED MESSAGES',
                'Encrypting s-w3s-www03 C:-drive',
                'Encrypting s-w3s-www03 D:-drive',
                'Encrypting s-w3s-www03 E:-drive',
                'ACCESSING s-w3s-www04',
                'Replacing all websites on s-w3s-www04 with HACKED MESSAGES',
                'Encrypting s-w3s-www04 C:-drive',
                'Encrypting s-w3s-www04 D:-drive',
                'Encrypting s-w3s-www04 E:-drive',
                'ACCESSING s-w3s-www05',
                'Replacing all websites on s-w3s-www05 with HACKED MESSAGES',
                'Encrypting s-w3s-www05 C:-drive',
                'Encrypting s-w3s-www05 D:-drive',
                'Encrypting s-w3s-www05 E:-drive',
                'ACCESSING s-w3s-www06',
                'Replacing all websites on s-w3s-www06 with HACKED MESSAGES',
                'Encrypting s-w3s-www06 C:-drive',
                'Encrypting s-w3s-www06 D:-drive',
                'Encrypting s-w3s-www06 E:-drive',
            ],
            introMessages: [
                'I have hacked your system and I want to play a game to make sure you are capable.',
                'Please do not touch the laptop!',
                'IF SOMEBODY LEAVES THIS ROOM OR YOU USE YOUR PHONE YOU WILL LOSE IMMEDIATELY!',
                'I want to play a game with you',
                'Find out who I am and you might win.',
                'You have thirty minutes before I have encrypted all of your servers and everything will be lost!',
                'if a hint is provided, you will hear this sound',
                'Good luck, your time has already started!',
            ],
            messages: [],
            lastMessageId: 0,
            encryptionMessageId: -1,
            hasWon: false,
            isAdmin: false,
            audio: null,
            hintText: '',
            hasStarted: false,
        };
    },
    async mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('admin')) {
            this.isAdmin = true;
        }
        if (!this.isAdmin) {
            this.playBackgroundMusic();
        }

        this.$questionHub.$on('sentMessage', this.receivedMessage);
        this.$questionHub.$on('incorrectAnswer', this.incorrectAnswer);
    },
    methods: {
        incorrectAnswer(message) {
            const myMessage = message.message;
            if (this.isAdmin) {
                const questionId = myMessage;
                console.log(myMessage.question);
                this.createMessage(
                    'antwoord op vraag ' +
                        questionId.question +
                        ' is ' +
                        myMessage.answer +
                        ' poging ' +
                        myMessage.tries
                );
            } else {
                this.createMessage('incorrect answer');
            }
        },
        start() {
            this.hasStarted = true;
            this.createMessage(
                `THE SUITEDH@CK0R_ [192.168.1.154] has joined conversation`,
                'server'
            );
            this.showFirstMessageInRow();
            setInterval(() => {
                if (!this.isAdmin && !this.hasWon && this.hasStarted) {
                    if (
                        this.encryptionMessageId ==
                        this.encryptionMessages.length - 1
                    ) {
                        this.encryptionMessageId = -1;
                    }
                    this.encryptionMessageId++;
                    this.createMessage(
                        this.encryptionMessages[this.encryptionMessageId],
                        'server'
                    );
                }
            }, 30000);
        },
        sendHint() {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    message: this.hintText,
                    author: 'server',
                    messageType: 'hint',
                    action: 'action',
                    dateTime: new Date(),
                }),
            };
            fetch(
                'https://birds.timeforgames.nl/DistributeMessages/SendMessage',
                requestOptions
            )
                .then(() => {
                    this.hintText = '';
                })
                .catch(() => {
                    this.createMessage('error sending hint', 'server');
                });
        },
        playBackgroundMusic() {
            this.audio = new Audio(require('../assets/music.mp3')); // path to file
            this.audio.autoplay = true;
            this.audio.loop = true;
            this.audio.play();
        },
        playHint() {
            const hintAudio = new Audio(require('../assets/hint.mp3')); // path to file
            hintAudio.autoplay = true;
            hintAudio.loop = false;
            hintAudio.play();
        },
        showFirstMessageInRow() {
            if (this.hasStarted) {
                this.waitTime = null;
                if (this.lastMessageId == -1) {
                    this.lastMessageId = 0;
                }
                const messageId = this.introMessages[this.lastMessageId];
                if (messageId == null) {
                    // wait for a new message
                    console.log('returned');
                    return;
                }
                const message = messageId;
                console.log(this.lastMessageId);
                if (this.lastMessageId == 6) {
                    console.log('played sound');
                    this.playHint();
                }
                this.createMessage(message);

                const waitTime = message.length * 100;
                this.lastMessageId++;
                setTimeout(this.showFirstMessageInRow, waitTime + 400);
            }
        },
        reset() {
            this.hasWon = false;
            this.hasStarted = false;
            this.messages = [];
            this.encryptionMessageId = -1;
        },
        // This is called from the server through SignalR
        receivedMessage({ message }) {
            if (message.message === 'start') {
                this.start();
            } else if (message.message === 'reset') {
                this.reset();
            } else if (message.message === 'won' || message.message === 'win') {
                this.hasWon = true;
                this.createMessage(
                    'You have won. You now know who I am and I will decrypt all of the files'
                );
            } else {
                this.createMessage('hint: ' + message.message, 'server');
                if (!this.isAdmin) {
                    this.playHint();
                }
            }
        },
        createMessage(inputMessage, messageType, author) {
            const message = {
                message: inputMessage,
                author: author,
                messageType: messageType ?? 'info',
                dateTime: new Date(),
            };
            this.messages.push(message);
        },
        async sendMultipleMessages(messages) {
            for (let index = 0; index < messages.length; index++) {
                let timeout = index + 1 + 1000;
                if (index == 0) {
                    // start without delay
                    this.createMessage(messages[index], 'info', 'Console');
                    timeout = 1000;
                } else {
                    // wait for previous line
                    setTimeout(() => {
                        console.log(messages[index]);
                        this.createMessage(messages[index], 'info', 'Console');
                    }, timeout);
                }
            }
        },
    },
    beforeDestroy() {
        // Make sure to cleanup SignalR event handlers when removing the component
        this.$questionHub.$off('sentMessage', this.onScoreChanged);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}

.main-input {
    flex: 1;
    overflow: hidden;
}
</style>
