<template>
    <div class="morse-code" :class="morseCss">
        <span class="light">⦿</span>
    </div>
</template>

<script>
const alphabet = {
    a: '.-',
    b: '-...',
    c: '-.-.',
    d: '-..',
    e: '.',
    f: '..-.',
    g: '--.',
    h: '....',
    i: '..',
    j: '.---',
    k: '-.-',
    l: '.-..',
    m: '--',
    n: '-.',
    o: '---',
    p: '.--.',
    q: '--.-',
    r: '.-.',
    s: '...',
    t: '-',
    u: '..-',
    v: '...-',
    w: '.--',
    x: '-..-',
    y: '-.--',
    z: '--..',
    ' ': '/',
    1: '.----',
    2: '..---',
    3: '...--',
    4: '....-',
    5: '.....',
    6: '-....',
    7: '--...',
    8: '---..',
    9: '----.',
    0: '-----',
};
export default {
    name: 'MorseCode',

    data() {
        return {
            phrase: '6015',
            morsePhrase: [],
            morseCss: '',
            i: 0,
            time: 0,
            morseText: ' ',
        };
    },
    created() {
        const nicePhrase = this.phrase
            .split('') // Transform the string into an array: ['T', 'h', 'i', 's'...
            .map(function (e) {
                // Replace each character with a morse "letter"
                return alphabet[e.toLowerCase()] || ''; // Lowercase only, ignore unknown characters.
            })
            .join(' ');
        this.morsePhrase = nicePhrase.split('');
    },
    mounted() {
        // this.morseLoop();
        setTimeout(this.morseLoop, 1000);
    },
    methods: {
        morseL() {
            this.morseCss = 'long';
            setTimeout(() => {
                this.morseCss = '';
            }, 1200);
        },
        morseS() {
            this.morseCss = 'short';
            setTimeout(() => {
                this.morseCss = '';
            }, 400);
        },
        morseN() {
            this.morseCss = '';
        },
        morseLoop() {
            setTimeout(() => {
                if (this.morsePhrase[this.i] === '-') {
                    this.morseL();
                    this.time = 1400;
                } else if (this.morsePhrase[this.i] === '.') {
                    this.morseS();
                    this.time = 800;
                } else if (this.morsePhrase[this.i] === ' ') {
                    this.morseN();
                    this.time = 1200;
                }

                this.i++;

                if (this.i < this.morsePhrase.length) {
                    this.morseLoop();
                } else {
                    this.i = 0;
                    setTimeout(this.morseLoop, 5000);
                }
            }, this.time);
        },
    },
};
</script>

<style lang="scss" scoped></style>
